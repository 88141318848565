import {useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {ApiConsumer} from "api/ApiConsumer";
import Loader from "components/Loader";
import {useUtils} from "hooks/UtilityHook";

export function StackedLine({url = '', height = '400'}) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    let {getMonth} = useUtils()

    const options = {
        title: "",
        legend: {position: 'bottom'},
        chartArea: {width: "50%"},
        isStacked: true,
        hAxis: {
            title: "",
            minValue: 0,
        },
        vAxis: {
            title: "",
        },
        series: {
            0: {color: '#5fa83f'},
            1: {color: '#f72585'}
        }
    };

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(url)
            .then(res => {
                let temp = res.data.slice(0, 8)
                let result = [];
                result.push(["Plays", "Recognised", "Unrecognised"])
                const keys = Object.keys(res.data.slice(0, 8));
                keys.forEach(function (key, i) {
                    result.push([getMonth(res.data.slice(0, 6)[key][Object.keys(temp[0])[0]]), res.data.slice(0, 6)[key][Object.keys(temp[0])[1]], res.data.slice(0, 6)[key][Object.keys(temp[0])[2]]]);
                });
                setData(result)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [url])

    return <>
        {
            loading ? <Loader upload_message={'loading'} full_height={true}/> :
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height={`${height}px`}
                    data={data}
                    options={options}
                    className={'shadow-2xl rounded'}
                />
        }
    </>
}
